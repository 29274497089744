export const Profession = [
  { value: "", label: "--Select Profession--" },
  // { value: "Accountant", label: "Accountant" },
  { value: "Actor", label: "Actor" },
  { value: "Agriculturalist", label: "Agriculturalist" },
  { value: "Air Traffic Controller", label: "Air Traffic Controller" },
  { value: "Architect", label: "Architect" },
  { value: "Artist", label: "Artist" },
  { value: "Astronaut", label: "Astronaut" },
  { value: "Biomedical Engineer", label: "Biomedical Engineer" },
  { value: "Brewer", label: "Brewer" },
  { value: "Business Person", label: "Business Person" },
  { value: "Carpenter", label: "Carpenter" },
  { value: "Chef", label: "Chef" },
  { value: "Chemist", label: "Chemist" },
  { value: "Civil Engineer", label: "Civil Engineer" },
  { value: "Clinical Psychologist", label: "Clinical Psychologist" },
  { value: "Content Creator", label: "Content Creator" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Dentist", label: "Dentist" },
  { value: "Electrician", label: "Electrician" },
  { value: "Economist", label: "Economist" },
  { value: "Engineer", label: "Engineer" },
  { value: "Environmental Scientist", label: "Environmental Scientist" },
  { value: "Event Planner", label: "Event Planner" },
  { value: "Entrepreneur", label: "Entrepreneur" },
  { value: "Fashion Designer", label: "Fashion Designer" },
  { value: "Financial Analyst", label: "Financial Analyst" },
  { value: "Firefighter", label: "Firefighter" },
  { value: "Farmer", label: "Farmer" },
  { value: "Fisherwoman", label: "Fisherwoman" },
  { value : "Fisherman", "label": "Fisherman" },
  { value: "Forensic Scientist", label: "Forensic Scientist" },
  { value: "Geneticist", label: "Geneticist" },
  { value: "Geologist", label: "Geologist" },
  { value: "Graphic Designer", label: "Graphic Designer" },
  { value: "Hairdresser", label: "Hairdresser" },
  { value: "Health Educator", label: "Health Educator" },
  { value: "Herbalist", label: "Herbalist" },
  { value: "IT Professional", label: "IT Professional" },
  { value: "Journalist", label: "Journalist" },
  { value: "Lawyer", label: "Lawyer" },
  { value: "Linguist", label: "Linguist" },
  { value: "Lecturer", label: "Lecturer" },
  { value: "Marketing Specialist", label: "Marketing Specialist" },
  { value: "Mechanic", label: "Mechanic" },
  { value: "Mechanical Engineer", label: "Mechanical Engineer" },
  { value: "Medical Doctor", label: "Medical Doctor" },
  { value: "Meteorologist", label: "Meteorologist" },
  { value: "Military", label: "Military" },
  { value: "Miner", label: "Miner" },
  { value: "Mobile App Developer", label: "Mobile App Developer" },
  { value: "Nurse", label: "Nurse" },
  { value: "Occupational Therapist", label: "Occupational Therapist" },
  { value: "Oil and Gas Engineer", label: "Oil and Gas Engineer" },
  { value: "Paramedic", label: "Paramedic" },
  { value: "Pharmacist", label: "Pharmacist" },
  { value: "Phlebotomist", label: "Phlebotomist" },
  { value: "Photographer", label: "Photographer" },
  { value: "Physiotherapist", label: "Physiotherapist" },
  { value: "Pilot", label: "Pilot" },
  { value: "Policeman", label: "Policeman" },
  { value: "Policy Maker", label: "Policy Maker" },
  { value: "Psychologist", label: "Psychologist" },
  { value: "Public Relations Specialist", label: "Public Relations Specialist" },
  { value: "Quality Assurance Analyst", label: "Quality Assurance Analyst" },
  { value: "Radiologist", label: "Radiologist" },
  { value: "Scientist", label: "Scientist" },
  { value: "Social Media Manager", label: "Social Media Manager" },
  { value: "Social Worker", label: "Social Worker" },
  { value: "Software Developer", label: "Software Developer" },
  { value: "Solar Panel Technician", label: "Solar Panel Technician" },
  { value: "Speech Therapist", label: "Speech Therapist" },
  { value: "Surveyor", label: "Surveyor" },
  // { value: "Teacher", label: "Teacher" },
  { value: "Textile Designer", label: "Textile Designer" },
  { value: "Tour Guide", label: "Tour Guide" },
  { value: "Tourism Consultant", label: "Tourism Consultant" },
  { value: "Tourism Manager", label: "Tourism Manager" },
  { value: "Traditional Weaver", label: "Traditional Weaver" },
  { value: "Translator", label: "Translator" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "Veterinarian", label: "Veterinarian" },
  { value: "Waste Management Specialist", label: "Waste Management Specialist" },
  { value: "Web Designer", label: "Web Designer" },
  { value: "Zoologist", label: "Zoologist" },
  { value: "Aerospace Engineer", label: "Aerospace Engineer" },
  { value: "Biochemist", label: "Biochemist" },
  { value: "Chiropractor", label: "Chiropractor" },
  { value: "Clinical Researcher", label: "Clinical Researcher" },
  { value: "Construction Manager", label: "Construction Manager" },
  { value: "Counselor", label: "Counselor" },
  { value: "Court Reporter", label: "Court Reporter" },
  { value: "Cybersecurity Expert", label: "Cybersecurity Expert" },
  { value: "Dietitian", label: "Dietitian" },
  { value: "Event Coordinator", label: "Event Coordinator" },
  { value: "Food Scientist", label: "Food Scientist" },
  { value: "Funeral Director", label: "Funeral Director" },
  { value: "Interior Designer", label: "Interior Designer" },
  { value: "Librarian", label: "Librarian" },
  { value: "Logistics Manager", label: "Logistics Manager" },
  { value: "Philanthropist", label: "Philanthropist" },
  { value: "Real Estate Agent", label: "Real Estate Agent" },
  { value: "Recruiter", label: "Recruiter" },
  { value: "Research Scientist", label: "Research Scientist" },
  { value: "Sculptor", label: "Sculptor" },
  { value: "Sound Engineer", label: "Sound Engineer" },
  { value: "Sports Coach", label: "Sports Coach" },
  { value: "Toxicologist", label: "Toxicologist" },
  { value: "Urban Planner", label: "Urban Planner" },
  { value: "Video Editor", label: "Video Editor" },
  { value: "Wedding Planner", label: "Wedding Planner" },
  { value: "Writer", label: "Writer" },
  { value: "AI Specialist", label: "AI Specialist" },
  { value: "Animator", label: "Animator" },
  { value: "Blockchain Developer", label: "Blockchain Developer" },
  { value: "Construction Worker", label: "Construction Worker" },
  { value: "Content Writer", label: "Content Writer" },
  { value: "Customer Support Specialist", label: "Customer Support Specialist" },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "Database Administrator", label: "Database Administrator" },
  { value: "Digital Marketer", label: "Digital Marketer" },
  { value: "Drone Pilot", label: "Drone Pilot" },
  { value: "E-commerce Specialist", label: "E-commerce Specialist" },
  { value: "Financial Planner", label: "Financial Planner" },
  { value: "Human Resources Manager", label: "Human Resources Manager" },
  { value: "Insurance Broker", label: "Insurance Broker" },
  { value: "IoT Specialist", label: "IoT Specialist" },
  { value: "Java Developer", label: "Java Developer" },
  { value: "Mechanical Drafter", label: "Mechanical Drafter" },
  { value: "Nutritionist", label: "Nutritionist" },
  { value: "Operations Manager", label: "Operations Manager" },
  { value: "Product Manager", label: "Product Manager" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Real Estate Developer", label: "Real Estate Developer" },
  { value: "SEO Specialist", label: "SEO Specialist" },
  { value: "Tax Consultant", label: "Tax Consultant" },
  { value: "Technical Writer", label: "Technical Writer" },
  { value: "UI/UX Researcher", label: "UI/UX Researcher" },
  { value: "VFX Artist", label: "VFX Artist" },
  { value: "Blockchain Analyst", label: "Blockchain Analyst" },
  { value: "Cloud Architect", label: "Cloud Architect" },
  { value: "Data Center Engineer", label: "Data Center Engineer" },
  { value: "Game Developer", label: "Game Developer" },
  { value: "Genetic Counselor", label: "Genetic Counselor" },
  { value: "Mobile App Designer", label: "Mobile App Designer" },
  { value: "Nanotechnologist", label: "Nanotechnologist" },
  { value: "Operations Analyst", label: "Operations Analyst" },
  { value: "Sustainability Manager", label: "Sustainability Manager" },
  { value: "User Experience Researcher", label: "User Experience Researcher" },
  { value: "Voiceover Artist", label: "Voiceover Artist" },
  { value: "Web Developer", label: "Web Developer" },
  { value: "Wind Turbine Technician", label: "Wind Turbine Technician" },
 
    { value : "Farmer", "label": "Farmer" },
    { value : "Teacher", "label": "Teacher" },
    // { value : "Nurse", "label": "Nurse" },
    { value : "Doctor", "label": "Doctor" },
    { value : "Engineer", "label": "Engineer" },
    { value : "Accountant", "label": "Accountant" },
    { value : "Banker", "label": "Banker" },
    { value : "Lawyer", "label": "Lawyer" },
    { value : "Software Developer", "label": "Software Developer" },
    { value : "Civil Servant", "label": "Civil Servant" },
    { value : "Electrician", "label": "Electrician" },
    { value : "Plumber", "label": "Plumber" },
    { value : "Carpenter", "label": "Carpenter" },
    { value : "Mechanic", "label": "Mechanic" },
    { value : "Driver", "label": "Driver" },
    { value : "Waiter", "label": "Waiter" },
    { value : "Security Guard", "label": "Security Guard" },
    { value : "Salesperson", "label": "Salesperson" },
    { value : "Marketing Executive", "label": "Marketing Executive" },
    { value : "Journalist", "label": "Journalist" },
    { value : "Photographer", "label": "Photographer" },
    { value : "Graphic Designer", "label": "Graphic Designer" },
    { value : "Pharmacist", "label": "Pharmacist" },
    { value : "Lab Technician", "label": "Lab Technician" },
    { value : "Architect", "label": "Architect" },
    { value : "Surveyor", "label": "Surveyor" },
    { value : "Real Estate Agent", "label": "Real Estate Agent" },
    { value : "Construction Worker", "label": "Construction Worker" },
    { value : "Tailor", "label": "Tailor" },
    { value : "Hairdresser", "label": "Hairdresser" },
    { value : "Beautician", "label": "Beautician" },
    { value : "Barber", "label": "Barber" },
    { value : "Pilot", "label": "Pilot" },
    { value : "Flight Attendant", "label": "Flight Attendant" },
    { value : "Travel Agent", "label": "Travel Agent" },
    { value : "Tour Guide", "label": "Tour Guide" },
    { value : "Hotel Manager", "label": "Hotel Manager" },
    { value : "Receptionist", "label": "Receptionist" },
    { value : "Customer Service Representative", "label": "Customer Service Representative" },
    { value : "Human Resources Manager", "label": "Human Resources Manager" },
    { value : "Administrative Assistant", "label": "Administrative Assistant" },
    { value : "Project Manager", "label": "Project Manager" },
    { value : "Business Analyst", "label": "Business Analyst" },
    { value : "Data Analyst", "label": "Data Analyst" },
    { value : "IT Support Specialist", "label": "IT Support Specialist" },
    { value : "Network Administrator", "label": "Network Administrator" },
    { value : "Database Administrator", "label": "Database Administrator" },
    { value : "Web Developer", "label": "Web Developer" },
    { value : "Mobile App Developer", "label": "Mobile App Developer" },
    { value : "Digital Marketer", "label": "Digital Marketer" },
    { value : "Social Media Manager", "label": "Social Media Manager" },
    { value : "Content Writer", "label": "Content Writer" },
    { value : "Editor", "label": "Editor" },
    { value : "Translator", "label": "Translator" },
    { value : "Interpreter", "label": "Interpreter" },
    { value : "Research Scientist", "label": "Research Scientist" },
    // { value : "Lecturer", "label": "Lecturer" },
    { value : "Professor", "label": "Professor" },
    { value : "Librarian", "label": "Librarian" },
    { value : "Consultant", "label": "Consultant" },
    { value : "Entrepreneur", "label": "Entrepreneur" },
    { value : "Business Owner", "label": "Business Owner" },
    { value : "Event Planner", "label": "Event Planner" },
    { value : "Public Relations Officer", "label": "Public Relations Officer" },
    { value : "Policy Analyst", "label": "Policy Analyst" },
    { value : "Economist", "label": "Economist" },
    { value : "Financial Analyst", "label": "Financial Analyst" },
    { value : "Investment Banker", "label": "Investment Banker" },
    { value : "Stockbroker", "label": "Stockbroker" },
    { value : "Insurance Agent", "label": "Insurance Agent" },
    { value : "Auditor", "label": "Auditor" },
    { value : "Tax Consultant", "label": "Tax Consultant" },
    { value : "Procurement Officer", "label": "Procurement Officer" },
    { value : "Supply Chain Manager", "label": "Supply Chain Manager" },
    { value : "Logistics Manager", "label": "Logistics Manager" },
    { value : "Warehouse Manager", "label": "Warehouse Manager" },
    { value : "Quality Control Officer", "label": "Quality Control Officer" },
    { value : "Environmental Scientist", "label": "Environmental Scientist" },
    { value : "Geologist", "label": "Geologist" },
    { value : "Meteorologist", "label": "Meteorologist" },
    { value : "Agronomist", "label": "Agronomist" },
    { value : "Butcher", "label": "Butcher" },
    { value : "Baker", "label": "Baker" },
    { value : "Bartender", "label": "Bartender" },
    { value : "Caterer", "label": "Caterer" },
    { value : "Shopkeeper", "label": "Shopkeeper" },
    { value : "Retail Store Manager", "label": "Retail Store Manager" },
    { value : "Street Vendor", "label": "Street Vendor" },
    { value : "Religious Leader", "label": "Religious Leader" },
  { value: "Other", label: "Other" }
];
