export const Business = [
  { value: "", label: "--Select--" },
  { value: "Health", label: "Health" },
  { value: "Business", label: "Business" },
  { value: "Build Environment", label: "Build Environment" },
  { value: "Music and Entertainment", label: "Music and Entertainment" },
  { value: "Education", label: "Education" },
  { value: "Banking and Finance", label: "Banking and Finance" },
  { value: "Media", label: "Media" },
  { value: "Information Technology", label: "Information Technology (IT)" },
  { value: "Fashion", label: "Fashion" },
  { value: "Hospitality and Tourism", label: "Hospitality and Tourism" },
  { value: "Social and Philanthropy", label: "Social and Philanthropy" },
  { value: "Security", label: "Security" },
  { value: "Legal and Judiciary", label: "Legal and Judiciary" },
  { value: "Transport", label: "Transport" },
  { value: "Farmers", label: "Farmers" },
  { value: "Fisherman", label: "Fisherman" },
  { value: "Market Woman", label: "Market Woman" },
  { value: "Politicians", label: "Politicians" },
  { value: "Mechanics", label: "Mechanics" },
  { value: "Mining", label: "Mining" },
  { value: "Technicians", label: "Technicians" },
  { value: "Other", label: "Other" },
];
