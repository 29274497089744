export const ChurchArea = [
      { "value": "", "label": "--Select--" },
      { "value": "Achimota", "label": "Achimota" },

      { "value": "Amrahia", "label": "Amrahia" },
      
      { "value": "Abuakwa", "label": "Abuakwa" },
      { "value": "Afram-Plains", "label": "Afram-Plains" },
      { "value": "Aflao", "label": "Aflao" },
      { "value": "Agona-Swedru", "label": "Agona-Swedru" },
      { "value": "Agormanya", "label": "Agormanya" },
      { "value": "Akim-Oda", "label": "Akim-Oda" },
      { "value": "Anyaa-Ablekuma", "label": "Anyaa-Ablekuma" },
      { "value": "Asankrangwa", "label": "Asankrangwa" },
      { "value": "Ashaiman", "label": "Ashaiman" },
      { "value": "Asante-Bekwai", "label": "Asante-Bekwai" },
      { "value": "Asokwa", "label": "Asokwa" },
      { "value": "Assin-Fosu", "label": "Assin-Fosu" },
      { "value": "Atebubu", "label": "Atebubu" },
      { "value": "Atonsu", "label": "Atonsu" },
      { "value": "Axim", "label": "Axim" },
      { "value": "Bawku", "label": "Bawku" },
      { "value": "Berekum", "label": "Berekum" },
      { "value": "Bogoso", "label": "Bogoso" },
      { "value": "Bolgatanga", "label": "Bolgatanga" },
      { "value": "Bompata", "label": "Bompata" },
      { "value": "Capecoast", "label": "Capecoast" },
      { "value": "Dansoman", "label": "Dansoman" },
      { "value": "Downtown-Ofankor", "label": "Downtown-Ofankor" },
      { "value": "Dormaa-Ahenkro", "label": "Dormaa-Ahenkro" },
      { "value": "Duayaw-Nkwanta", "label": "Duayaw-Nkwanta" },
      { "value": "Duankwa", "label": "Duankwa" },
      { "value": "Ejisu", "label": "Ejisu" },
      { "value": "Essam-Debiso", "label": "Essam-Debiso" },
      { "value": "Goaso", "label": "Goaso" },
      { "value": "Haatso", "label": "Haatso" },
      { "value": "Ho", "label": "Ho" },
      { "value": "Hohoe", "label": "Hohoe" },
      { "value": "Juaboso", "label": "Juaboso" },
      { "value": "Kade", "label": "Kade" },
      { "value": "Kaneshie", "label": "Kaneshie" },
      { "value": "Kasoa", "label": "Kasoa" },
      { "value": "Kete-Krachi", "label": "Kete-Krachi" },
      { "value": "Kintampo", "label": "Kintampo" },
      { "value": "Koforidua", "label": "Koforidua" },
      { "value": "Koforidua-Effiduase", "label": "Koforidua-Effiduase" },
      { "value": "Konongo", "label": "Konongo" },
      { "value": "Kwadaso", "label": "Kwadaso" },
      { "value": "La", "label": "La" },
      { "value": "Madina", "label": "Madina" },
      { "value": "Mampong", "label": "Mampong" },
      { "value": "Mankessim", "label": "Mankessim" },
      { "value": "New-Tafo", "label": "New-Tafo" },
      { "value": "Nkawkaw", "label": "Nkawkaw" },
      { "value": "Nsawam", "label": "Nsawam" },
      { "value": "Obuasi", "label": "Obuasi" },
      { "value": "Odorkor", "label": "Odorkor" },
      { "value": "Offinso", "label": "Offinso" },
      { "value": "Old-Tafo", "label": "Old-Tafo" },
      // { "value": "Pensa-Knust", "label": "Pensa-Knust" },
      // { "value": "Pensa-Legon", "label": "Pensa-Legon" },
      // { "value": "Pensa-Ucc", "label": "Pensa-Ucc" },
      // { "value": "Pensa-Uew", "label": "Pensa-Uew" },
      { "value": "Sawla", "label": "Sawla" },
      { "value": "Sefwi-Bekwai", "label": "Sefwi-Bekwai" },
      { "value": "Sefwi-Juabuso", "label": "Sefwi-Juabuso" },
      { "value": "Sefwi-Wiawso", "label": "Sefwi-Wiawso" },
      { "value": "Sekondi", "label": "Sekondi" },
      { "value": "Sogakope", "label": "Sogakope" },
      { "value": "Suame", "label": "Suame" },
      { "value": "Suhum", "label": "Suhum" },
      { "value": "Sunyani", "label": "Sunyani" },
      { "value": "Takoradi", "label": "Takoradi" },
      { "value": "Tamale", "label": "Tamale" },
      { "value": "Techiman", "label": "Techiman" },
      { "value": "Tema", "label": "Tema" },
      { "value": "Tepa", "label": "Tepa" },
      { "value": "Tarkwa", "label": "Tarkwa" },
      { "value": "Teshie-Nungua", "label": "Teshie-Nungua" },
      { "value": "Tumu", "label": "Tumu" },
      { "value": "Twifi-Praso", "label": "Twifi-Praso" },
      { "value": "Wa", "label": "Wa" },
      { "value": "Walewale", "label": "Walewale" },
      { "value": "Winneba", "label": "Winneba" },
      { "value": "Yendi", "label": "Yendi" },
    ]
  
  